<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    :title="$t('Add product to list combo')"
  >
    <!-- <b-row>
      <b-col md="3">
        <ProductCategorySort
          :list="catItems"
          @handlerChangeCategory="handlerChangeCategory"
        />
      </b-col>
      <b-col md="9">
        <ProductList v-model="value" :list="items" />
      </b-col>
    </b-row> -->
    <ProductList v-model="value" :list="items" />
  </b-modal>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable array-callback-return */
// import { BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    // BRow,
    // BCol,
    // ProductCategorySort,
    ProductList,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    // productList: {
    //   type: Object,
    //   default() {
    //     return {}
    //   },
    // },
  },
  data() {
    return {
      items: [],
      catItems: [],
      productListTemp: [],
    }
  },
  mounted() {
    this.loadList()
    this.loadListCategory()
  },
  methods: {
    handlerChangeCategory(data) {
      this.items = this.productListTemp.filter(
        x => x.category_slug && x.category_slug[data.index] !== undefined && x.category_slug[data.index] === data.item.slug,
      )
    },
    async loadList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&is_not_combo=1&sort[]=id,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.items.map((val, idx) => {
              const item = {
                ...val,
                idx,
              }
              this.items.push(item)
              this.productListTemp.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadListCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.catItems = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
